<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <div class="page-content pb-0">
      <div data-card-height="" class="card mb-0">
        <div class="content">
          <p class="mb-n1 color-highlight font-600 font-12">Edit Profil</p>
          <h4>Profil Pencari Kerja</h4>
          <!-- Form -->
          <div class="mt-5 mb-3">
            <p v-if="errors" class="color-red-dark mb-3">
              {{ errors.errorMsg }}
            </p>
            <div
              class="input-style has-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': dataProfile.first_name }"
            >
              <input
                type="name"
                class="form-control validate-name"
                id="form1"
                v-model="dataProfile.first_name"
                placeholder="Nama Depan"
                required
              />
              <label for="form1">Nama Depan</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!dataProfile.first_name"
                :class="{
                  'color-red-dark font-800 req-val': errors.first_name,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style has-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': dataProfile.last_name }"
            >
              <input
                type="name"
                class="form-control validate-name"
                id="form1a"
                v-model="dataProfile.last_name"
                placeholder="Nama Belakang"
                required
              />
              <label for="form1a">Nama Belakang</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                v-if="!dataProfile.last_name"
                :class="{
                  'color-red-dark font-800 req-val': errors.last_name,
                }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                has-borders no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': dataProfile.country }"
            >
              <select class="form-control"  id="form1k" v-model="dataProfile.country">
                <option value="default" disabled="" selected="">Negara</option>
                <option
                  v-for="country in master_data.country"
                  :key="country.id"
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
              <label for="form1k">Negara</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!dataProfile.country"
                class="me-3"
                :class="{
                  'color-red-dark font-800 req-val': errors.country,
                }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                has-borders no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': province }"
            >
              <select class="form-control" id="form1k" v-model="province">
                <option value="default" disabled="" selected="">
                  Provinsi
                </option>
                <option
                  v-for="province in master_data.province"
                  :key="province.id"
                  :value="province.id"
                >
                  {{ province.name }}
                </option>
              </select>
              <label for="form1k">Provinsi</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!province"
                class="me-3"
                :class="{
                  'color-red-dark font-800 req-val': errors.province,
                }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                has-borders no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': dataProfile.city }"
            >
              <select class="form-control" id="form1k" v-model="dataProfile.city">
                <option value="default" disabled selected>
                  Pilih Domisili
                </option>
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
              <label for="form1k">Domisili</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!dataProfile.city"
                class="me-3"
                :class="{
                  'color-red-dark font-800 req-val': errors.city,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style has-borders no-icon mb-4"
              :class="{ 'input-style-active': dataProfile.background }"
            >
              <textarea
                id="form1c"
                v-model="dataProfile.background"
                placeholder="Latar belakang"
                required
              ></textarea>
              <label for="form1c">Latar Belakang</label>
            </div>

            <div
              class="input-style has-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': dataProfile.year_start_working }"
            >
              <input
                type="tel"
                class="form-control validate-name"
                id="form1e"
                v-model="dataProfile.year_start_working"
                placeholder="Tahun Awal Mulai Bekerja"
                maxlength="4"
                required
              />
              <label for="form1e">Awal Mulai Bekerja</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
            </div>

            <div
              class="input-style has-borders no-icon mb-4"
              :class="{ 'input-style-active': dataProfile.introduction_letter }"
            >
              <textarea
                id="form1d"
                v-model="dataProfile.introduction_letter"
                placeholder="Pengenalan tentang anda, misal saya seorang developer web...."
                required
              ></textarea>
              <label for="form1f">Pengenalan</label>
            </div>

            <div
              class="input-style has-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': dataProfile.current_job_title }"
            >
              <input
                type="email"
                class="form-control validate-name"
                id="form1g"
                v-model="dataProfile.current_job_title"
                placeholder="Nama Pekerjaan Saat ini"
                required
              />
              <label for="form1g"> Nama Pekerjaan Sekarang </label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
            </div>

            <div
              class="
                input-style
                has-borders
                no-icon
                validate-field
                mb-4
                input-style-active
              "
            >
              <input
                type="tel"
                class="form-control validate-name"
                id="form1g"
                v-model="dataProfile.current_job_salary"
                placeholder="misal 50000"
                required
                :class="{
                  'opacity-50':
                    !inCurrentSal && !dataProfile.current_job_salary,
                }"
                @focus="inCurrentSal = true"
                @blur="inCurrentSal = false"
              />
              <label for="form1g">Gaji Pekerjaan Sekarang</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
            </div>

            <div
              class="input-style has-borders no-icon mb-4"
              :class="{ 'input-style-active': dataProfile.current_job_desc }"
            >
              <textarea
                id="form1c"
                v-model="dataProfile.current_job_desc"
                placeholder="Deskripsi Pekerjaan Sekarang"
                required
              ></textarea>
              <label for="form1c">Deskripsi Pekerjaan</label>
            </div>

            <div
              class="input-style has-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': dataProfile.current_job_start }"
            >
              <input
                type="2021"
                class="form-control validate-name"
                id="form1g"
                v-model="dataProfile.current_job_start"
                maxlength="4"
                placeholder="Tahun mulai pekerjaan sekarang"
                required
              />
              <label for="form1g">Tahun Bekerja</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
            </div>

            <div
              class="input-style has-borders no-icon validate-field mb-4"
              key=""
              :class="{ 'input-style-active': dataProfile.last_degree }"
            >
              <select
                class="custom-form-control"
                id="form1h"
                v-model="dataProfile.last_degree"
              >
                <option value="default" disabled="" selected="">
                  Lulusan Terakhir
                </option>
                <option value="SD">SD</option>
                <option value="SMP">SMP</option>
                <option value="SMA">SMA</option>
                <option value="D3">D3</option>
                <option value="S1">S1</option>
                <option value="S1">S2</option>
                <option value="S3">S3</option>
              </select>
              <label for="form1h">Lulusan Terakhir</label>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
            </div>

            <div
              class="input-style has-borders no-icon validate-field mb-4"
              :class="{ 'input-style-active': dataProfile.year_graduate }"
            >
              <select
                class="custom-form-control"
                id="form1h"
                v-model="dataProfile.year_graduate"
              >
                <option value="default" disabled="" selected="">
                  Tahun Lulus
                </option>
                <option value="1990">1990</option>
                <option value="1991">1991</option>
                <option value="1992">1992</option>
                <option value="1993">1993</option>
                <option value="1994">1994</option>
                <option value="1995">1995</option>
                <option value="1996">1996</option>
                <option value="1997">1997</option>
                <option value="1998">1998</option>
                <option value="1999">1999</option>
                <option value="2000">2000</option>
                <option value="2001">2001</option>
                <option value="2002">2002</option>
                <option value="2003">2003</option>
                <option value="2004">2004</option>
                <option value="2005">2005</option>
                <option value="2006">2006</option>
                <option value="2007">2007</option>
                <option value="2008">2008</option>
                <option value="2009">2009</option>
                <option value="2010">2010</option>
                <option value="2011">2011</option>
                <option value="2012">2012</option>
                <option value="2013">2013</option>
                <option value="2014">2014</option>
                <option value="2015">2015</option>
                <option value="2016">2016</option>
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
              </select>
              <label for="form1">Tahun Lulus</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                :class="{
                  'color-red-dark font-800 req-val': errors.year_graduate,
                }"
                >(required)</em
              >
            </div>

            <div class="row mb-0">
              <label for="form1a">Gaji Yang di Inginkan</label>
              <div
                class="
                  input-style
                  col-6
                  has-borders
                  no-icon
                  validate-field
                "
                :class="{ 'input-style-active': dataProfile.pref_salary_start }"
              >
                <input
                  type="text"
                  class="form-control validate-name"
                  id="form1a"
                  v-model="dataProfile.pref_salary_start"
                  :class="{
                    'opacity-50':
                      !inPrefSalStart && !dataProfile.pref_salary_start,
                  }"
                  @focus="inPrefSalStart = true"
                  @blue="inPrefSalStart = false"
                  placeholder="minimal 10000"
                  required
                />
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
              </div>

              <div
                class="
                  input-style
                  col-6
                  has-borders
                  no-icon
                  validate-field
                "
                :class="{ 'input-style-active': dataProfile.pref_salary_end }"
              >
                <input
                  type="text"
                  class="form-control validate-name"
                  id="form1a"
                  v-model="dataProfile.pref_salary_end"
                  :class="{
                    'opacity-50': !inPrefSalEnd && !dataProfile.pref_salary_end,
                  }"
                  @focus="inPrefSalEnd = true"
                  @blur="inPrefSalEnd = false"
                  placeholder="misal 50000"
                  required
                />
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
              </div>
            </div>

            <p class="font-18 font-500 mb-3 mt-5 color-highlight">
              Pekerjaan Yang diinginkan
            </p>
            <!-- Category -->

            <div
              class="
                input-style
                has-borders
                no-icon
                validate-field
                mb-4
                input-style-always-active
              "
            >
              <select class="form-control" id="form1bc" v-model="categoryL1">
                <option value="default" disabled selected>
                  Kategori Level 1
                </option>
                <option
                  v-for="category in category.level1"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
              <label for="form1bc">Kategori Level 1</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!categoryL1"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.category }"
                >(required)</em
              >
            </div>
            <!-- Category level 2 -->

            <div
              class="
                input-style
                has-borders
                no-icon
                validate-field
                mb-4
                input-style-always-active
              "
            >
              <select class="form-control" id="form1bd" v-model="categoryL2">
                <option value="default" disabled selected>
                  Kategori Level 2
                </option>
                <option
                  v-for="category in category.level2"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
              <label for="form1bd">Kategori level 2</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!categoryL2"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.category }"
                >(required)</em
              >
            </div>

            <!-- Category level 3 -->
            <div
              class="
                input-style
                has-borders no-icon
                input-style-always-active
                validate-field
                mb-4
              "
            >
              <select
                class="form-control"
                id="form1be"
                v-model="dataProfile.current_job_category"
              >
                <option value="default" disabled selected>
                  Kategori Pekerjaan
                </option>
                <option
                  v-for="category in category.level3"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>

              <label for="form1be">Kategori</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!dataProfile.current_job_category"
                class="me-3"
                :class="{ 'color-red-dark font-800 req-val': errors.category }"
                >(required)</em
              >
            </div>

            <!-- End Category -->

            <div
              class="
                input-style
                has-borders
                no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': dataProfile.current_job_level }"
            >
              <select class="form-control" id="form1h" v-model="dataProfile.current_job_level">
                <option  value="default" disabled="">Level Pekerjaan</option>
                <option
                  v-for="level in master_data.job_level"
                  :key="level.id"
                  :value="level.id"
                >
                  {{ level.name }}
                </option>
              </select>
              <label for="form1h">Level Pekerjaan</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
            </div>

            <div
              class="
                input-style
                has-borders
                no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': dataProfile.pref_company_type }"
            >
              <select class="form-control" id="form1h" v-model="dataProfile.pref_company_type">
                <option value="default" disabled="">Jenis Perusahaan</option>
                <option
                  v-for="type in master_data.business_type"
                  :key="type.id"
                  :value="type.id"
                >
                  {{ type.name }}
                </option>
              </select>
              <label for="form1h">Tipe Perusahaan yang di inginkan</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
            </div>

            <p class="font-18 font-500 mb-3 mt-5 color-highlight">
              Status
            </p>

            <div
              class="
                input-style
                has-borders no-icon
                validate-field
                mb-4
                input-style-always-active
              "
              :class="{ 'input-style-active': dataProfile.country }"
            >
              <select class="form-control"  id="form1k" v-model="userBadge">
                <option value="0">Nonaktif</option>
                <option value="1">Aktif</option>
              </select>
              <label for="form1k">Badge Open to Work?</label>
              <span><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!dataProfile.country"
                class="me-3"
                :class="{
                  'color-red-dark font-800 req-val': errors.country,
                }"
              >(required)</em
              >
            </div>

            <!-- Submit Form Button -->
            <a
              @click.prevent="submitForm"
              href="#"
              class="
                btn btn-full btn-m
                gradient-highlight
                rounded-s
                font-13 font-600
                mt-4
              "
              >Simpan Perubahan</a
            >
          </div>
          <!--End Form -->
        </div>
      </div>
    </div>
    <!--End Page Content -->
    <!-- Update modals -->
    <div
      id="updateProfile"
      class="menu menu-box-modal rounded-m"
      data-menu-hide="1500"
      data-menu-width="250"
      data-menu-height="170"
    >
      <h1 class="text-center mt-3 pt-2">
        <i class="fa fa-check-circle scale-icon fa-3x color-green-dark"></i>
      </h1>
      <h3 class="text-center pt-2">Profil berhasil diperbaharui</h3>
    </div>
    <!-- End Update modals-->
    <div
      id="validate-required"
      class="menu menu-box-modal bg-blue-dark rounded-m"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px; height: 310px"
    >
      <h1 class="text-center mt-4">
        <i
          class="
            fa fa-3x fa-times-circle
            scale-box
            color-white
            shadow-xl
            rounded-circle
          "
        ></i>
      </h1>
      <p class="boxed-text-l color-white opacity-70">
        Beberap field wajib diisi<br />
        pastikan yang bertanda merah sudah terisi
      </p>
      <a
        @click="menuClose"
        href="#"
        class="
          close-menu
          btn btn-m btn-center-l
          button-s
          shadow-l
          rounded-s
          text-uppercase
          font-600
          bg-white
          color-black
        "
        >Keluar</a
      >
    </div>

    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuOpen, menuClose } from "@/components/menu/index.js";
import Header from "@/components/Header.vue";
export default {
  name: "JobSeekerProfile",
  components: {
    Header,
  },
  data() {
    return {
      apiEndpoint: process.env.VUE_APP_ROOT_API_NODE,
      business_name: "",
      dataProfile: {
        first_name: "",
        last_name: "",
        country: "",
        city: "",
        background: "",
        year_start_working: "",
        introduction_letter: "",
        current_job_title: "",
        current_job_start: "",
        current_job_salary: "",
        current_job_desc: "",
        last_degree: "",
        year_graduate: "",
        pref_salary_start: "",
        pref_salary_end: "",
        current_job_category: "",
        current_job_level: "",
        pref_company_type: "",
        skills: [],
      },
      category: {
        level1: [],
        level2: [],
        level3: [],
      },
      categoryL1: "",
      categoryL2: "",
      categoryL3: "",
      errors: [],
      inCurrentSal: false,
      inPrefSalStart: false,
      inPrefSalEnd: false,
      province: "",
      master_data: {},
      api_url: "/api/v1/job-seeker/profile/",
      method: "post",
      isLoading: true,
      cities: [],
      userBadge: 0
    };
  },
  props: {
    data: Object,
  },
  watch: {
    province() {
      this.cities = this.master_data.province.filter((item) => {
        return item.id === this.province;
      });
      if (this.cities[0]) {
        this.cities = this.cities[0].city;
      }
    },

    categoryL1() {
      this.category.level2 = this.allCategory.filter((item) => {
        return item.parent === this.categoryL1;
      });
    },

    categoryL2() {
      this.category.level3 = this.allCategory.filter((item) => {
        return item.parent === this.categoryL2;
      });
    },
  },
  mounted() {
    init_template();
    this.master_data = this.$store.state.master_data;
    this.getData();
    this.getStatusBadge();
  },
  methods: {
    /* get company profile data*/
    menuOpen,
    menuClose,
    checkRequired() {
      this.errors = {};
      // check required field not null
      var dataInput = {
        first_name: this.dataProfile.first_name,
        last_name: this.dataProfile.last_name,
        last_degree: this.dataProfile.last_degree,
        year_graduate: this.dataProfile.year_graduate,
        country: this.dataProfile.country,
        province: this.province,
        city: this.dataProfile.city,
      };

      for (const key in dataInput) {
        if (dataInput[key] === "") {
          this.errors[key] = "Empty Input";
          this.errors["errorMsg"] = "Beberapa form wajib di isi";
        }
      }
      if (this.errors.errorMsg) {
        this.menuOpen("validate-required");
      }
    },
    async getCategory() {
      await axios
        .get("api/v1/employer/category/?all_category=true")
        .then((response) => {
          this.allCategory = response.data;
          this.category.level1 = response.data.filter((item) => {
            return item.level === "L1";
          });
          this.category.level3 = response.data.filter((item) => {
            return item.level === "L3";
          });
          if (this.dataProfile.current_job_category) {
            var userCategory = response.data.filter((item) => {
              return item.id === this.dataProfile.current_job_category;
            });
            console.log(response.data);
            console.log(userCategory);
            var dataCategoryL2 = response.data.filter((item) => {
              return item.id === userCategory[0].parent
                ? (this.categoryL2 = item.id)
                : "";
            });

            response.data.filter((item) => {
              return item.id === dataCategoryL2[0].parent
                ? (this.categoryL1 = item.id)
                : "";
            });
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.errors = [];
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
            /*import "../assets/code/scripts/custom.js";*/
          }
        });
    },
    async getData() {
      this.errors = [];
      await axios
        .get(this.api_url)
        .then((response) => {
          if (response.data.length === 1) {
            this.dataProfile = response.data[0];

            // category

            //format currency
            if (response.data[0].pref_salary_start) {
              this.dataProfile["pref_salary_start"] = this.$filters.formatPrice(
                response.data[0]["pref_salary_start"]
              );
            }

            //format currency
            if (response.data[0].pref_salary_end) {
              this.dataProfile["pref_salary_end"] = this.$filters.formatPrice(
                response.data[0]["pref_salary_end"]
              );
            }

            this.province = response.data[0].province;
            delete this.dataProfile.resume_upload;
            this.method = "patch";
          }
          this.checkRequired();
          this.getCategory();
        })
        .catch((error) => {
          this.errors = [];
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log("error :", JSON.stringify(error));
          }
        });
    },
    async getStatusBadge() {
      this.errors = [];
      await axios
        .get("user/get-badge", {
          baseURL: this.apiEndpoint,
        })
        .then(({ data }) => {
          this.userBadge = data.data.status_badge
        })
        .catch((error) => {
          this.errors = [];
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log("error :", JSON.stringify(error));
          }
        });
    },
    async updateStatusBadge() {
      this.errors = [];
      let status = false
      await axios
        .post("user/change-badge", {
          status_badge: this.userBadge
        },{
          baseURL: this.apiEndpoint,
        })
        .then(({ data }) => {
          status = true
        })
        .catch((error) => {
          this.errors = [];
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log("error :", JSON.stringify(error));
          }
          status = false
        });
      return status;
    },
    validateInput(dataInput) {
      this.errors = {};
      var reqInput = {
        first_name: this.dataProfile.first_name,
        last_name: this.dataProfile.last_name,
        last_degree: this.dataProfile.last_degree,
        year_graduate: this.dataProfile.year_graduate,
        country: this.dataProfile.country,
        province: this.province,
        city: this.dataProfile.city,
      };

      //validate pref salary  input
      if (dataInput.pref_salary_start && dataInput.pref_salary_start < 10000) {
        this.errors["pref_salary_start"] = "Tidak boleh kurang dari 10000";
        this.errors["errorMsg"] = "Tidak boleh kurang dari 10000";
      }

      if (
        parseInt(dataInput.pref_salary_start) >
        parseInt(dataInput.pref_salary_end)
      ) {
        this.errors["pref_salary_start"] = " Lebih kecil";
        this.errors["errorMsg"] =
          "gaji minimal yang diinginkan tidak boleh lebih dari gaji maksimal";
      }

      // validate required field
      for (const key in reqInput) {
        if (dataInput[key] === "") {
          this.errors[key] = "Empty Input";
          this.errors["errorMsg"] = "Pastikan sudah form sudah di isi";
        }
      }
    },
    /*update data*/
    async submitForm() {
      const formData = this.dataProfile;

      if (formData.pref_salary_start) {
        formData["pref_salary_start"] = formData["pref_salary_start"]
          .split(".")
          .join("");
      }

      if (formData.pref_salary_end) {
        formData["pref_salary_end"] = formData["pref_salary_end"]
          .split(".")
          .join("");
      }

      formData["province"] = this.province;

      this.validateInput(formData);

      if (!Object.keys(this.errors).length) {
        var request_method = "";
        if (this.method === "patch") {
          var url = this.api_url + this.dataProfile.id + "/";
          request_method = axios.patch(url, formData);
        } else if (this.method === "post") {
          /*create profile*/
          request_method = axios.post(this.api_url, formData);
        }

        await this.updateStatusBadge()

        await request_method
          .then((response) => {
            console.log(response);
            this.dataProfile = response.data;
            this.menuOpen("updateProfile");

            setTimeout(() => this.$router.push({ name: "Dashboard" }), 2000);
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors[
                  "error"
                ] = `${property}: ${error.response.data[property]}`;
              }
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              this.errors["error"] = "Something went wrong. please try again";
              console.log(JSON.stringify(error));
            }
          });
      }
      /*update*/
    },
  },
};
</script>
